.font{
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 20px;
    /* font-s;     */
    text-transform: uppercase;
    line-height: 20px;
    float: left;
    /* border-bottom: 1px solid #9b9898; */
}
.imgbox{
    margin: 0%;
}
.boxheight{
    /* height: 350px; */
    /* align-items: center;
    align-content: center; */
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.bluebox{
    /* background-color:  #bdbdbd; */
    /* padding: 5%; */
    color: white;
    /* padding-top: 15%; */
    text-align: center;    
    z-index: 1;
    width: 50%;
    height: -webkit-fill-available;
}
.whitebox{
    margin: 0%;
    background-color: #fff;
    width: 50%;
    /* opacity: 80%; */
    border-radius: 0 10px 10px 0;
    box-shadow: 2px 0px 13px #bdbdbd;
/* box-shadow:  6px 14px 0px rgb(0 0 0 / 26%) */
}
.whitebox div input{
    margin-top: 1.5rem;
}
.whitebox div{
    margin-top: 0.5rem;
}
::-webkit-input-placeholder {
    font-size: 15px;
 }
 .contactfont{
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 20px;
    /* font-s;     */
    text-transform: uppercase;
    line-height: 20px;

 }
.carousel.min.css{
    visibility: hidden;
}
.backimg{
    z-index: 1;
    /* position: absolute; */
    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
    /* left: 0; */
    /* opacity: 1; */
}
.borders{
    /* border-bottom: 1px solid #9b9898; */
    /* padding-left: 0% !important; */
    text-align: center;
    margin: 2% 0;
    font-weight: 400!important;
    font-size: 25px;
}
.App1{
    background-color: #ffffff;
}
.App{
    /* background-color: #ffffff;     */
}
.para{
    font-size: 17px;
    line-height: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    /* justify-content: space-evenly; */
    text-align: justify;
    margin-top: 3%;
    /* padding: 0 6rem; */
}
.content{
    /* position: absolute; */
    right: 5vw;
    margin-top: 5%;
    /* padding: 0 5%; */
    right: 0%;
}
.abouthead{
    /* display: flex; */
    /* text-align: center; */
    /* align-items: center; */
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    line-height: 50px;
    /* text-align: justify; */
    justify-content: center;
    margin: 5% 0;
}
.aboutpara{
    /* display: grid; */
    /* text-align: center; */
    /* align-items: center; */
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    line-height: 40px;
    text-align: justify;
    font-weight: 600 !important;
}
.aboutpara2{
    /* display: grid; */
    /* text-align: center; */
    /* align-items: center; */
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    /* justify-content: center; */
    line-height: 25px;
    /* color: #000000; */
    text-align: justify;
    margin: 5% 0;
}
.aboutpic{
    display: grid;
    justify-content: center;
    align-content: center;
    height: 65vh;
    /* font-size: 4.5rem; */
    left:100px;
    margin-top: 5%;
}
.aboutwall{
    position: absolute;
    height: 90%;
    width: 60%;    
    border-radius: 2px;
}
.aboutwall2{
    z-index: 1;
    width: 40%;
    height: 50%;
    position: absolute;
    border-radius: 2px;
    right: 20%;
    /* margin: 70% 0 0 10%; */
}
.proimg{
    height: 30vh;
    width: 100%;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}
.whatwedo{
    /* height: 40vh; */
    display: flex;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    text-align: center;
    padding: 5%;
}
.weredo{
    width: 33.3333%;
    font-size: 35px;
}
.rrr{
    width: 33.3333%;
}
.gogreen{
    width: 33.3333%;
    font-size: 17px;
    text-align: justify;
}
.rrrimage{
    width: 150px;
    height: 100px;
}
/* .carousel:hover img{
    filter: blur(1.5px);
} */
.style{
    position: absolute;
    height: 25%;
    background-color: #00000050;
    bottom: 0;
    opacity: 1s;
    transition: all .7s ease-in;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: 900;
    font-size: 25px;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    /* transform: translateX(100px); */
}
.style label{
    font-size: 35px;
    /* transform: translateX(100rem) translateX(0rem); */
    /* transition: all 1s ease; */
    animation: labelanime 1s ease-in;
}

@keyframes labelanime {
    from {
        right: 100rem;
    }
    to {
        right: 0rem;
    }
}


@-webkit-keyframes labelanime {
    from {
        right: 100rem;
    }
    to {
        right: 0rem;
    }
  }

/* .carousel:hover .style{
    opacity: 1;

} */

/* .scaleincenter {
	-webkit-animation: scale-in-center 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */

@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
.contacts{
    margin: 5% 0 0 0;
}

/* ======= Carousal box css start========= */

/* .proimg:hover{
            box-shadow: inset 200px 0 0 0 rgba(0, 0, 0, 0.5);
    } */
.prodfont{
    height: 13vh;
    color: #000000;
    font-size: 20px;
    /* line-height: 22px; */
    /* font-weight: 600; */
    position: absolute;
    bottom: 0px;
    opacity: 1;
    text-align: center;
    /* padding-left: 5rem;
    padding-right: 5rem; */
    padding: 0 4rem;
    font-family: 'Roboto', sans-serif;
    z-index: 9;
    background-color: #00000050;
    transform: translateY(35px);
    /* height: auto; */
    /* transform: translateY(3px); */
    /* -webkit-transform: translateY(3px); */

}
.prodfont .button{
    -webkit-transition: all .5s ease-in-out;
    transition: .5s;
    /* position:absolute; */
    opacity: 0;
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    background-color: #3b3380;
    color: white !important;
}
.box{
    /* font-size: 25px; */
    transition: .5s;
    /* box-shadow: inset 0px 0 0 0 rgba(0, 0, 0, 0.5) !important; */
    /* background-color: inset 200px 0 0 0 rgba(0, 0, 0 /26%); */
    /* opacity: 1; */
    height: 40vh;
    padding: 0%;
    overflow: hidden;
}
.totalbox{
    padding:0%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}
.totalbox:hover .prodfont{
transform: translateY(0px);
transition: all .5s ease-in-out;
-webkit-transition: all .5s ease-in-out;
/* color: rgb(255, 0, 0); */
}

.totalbox:hover .button{
    visibility: visible;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    opacity: 1;
    color: white;
    left: 10rem
}
/* .prodfont:hover .box { 
-webkit-filter: blur(3px);
filter: blur(3px);
transform: scale(1.1);
-webkit-transform: scale(1.1);
} */
.getmore{
        background-color: #3b3380;
        color: white;
        margin-top:5%
}
.box:hover { 
        /* box-shadow:  6px 14px 0px rgb(0 0 0 / 50%); */
    /* background-color: black; */
    /* border-radius: 25px 0px 0px 25px; */
    /* background-color: 6px */
    /* transition: 1s; */
    /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
    /* -webkit-filter: grayscale(1); */
    /* filter: grayscale(1); */

    filter: blur(3px);
    -webkit-filter: blur(3px);

    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    /* opacity: 1; */


 }
 /* .box:hover .proimg{
    opacity: 1;
     box-shadow: 6px 14px 0px rgb(0 0 0 / 75%);  

} */
/* 
.box:hover .text{
    font-size: 15px;
    position: absolute;
    font-family: 'Titillium Web', sans-serif;
    top: 50%;
    left: 50%;
    transition: .5s ease;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    
    opacity: 1;
} */
    /* background-image: linear-gradient(to right, #f0f0f0 , rgb(48, 48, 48)); */


/* .backimage{
z-index:-1;
position: absolute;
width: 100%;
height: 100%;
transform: rotate3d(1, 1, 1, 45deg);
} */

/* .text{
 opacity: 0;
} */

/* ======= Carousal box css end========= */


/*==========Responsive ============*/
@media only screen and (min-width:280px) and (max-width:480px) {
    .App{
        min-height: 0;
    }
    .font{
        font-size: 17px;
    }    
    .para{
        font-size: 15px;
        line-height: 22px;
    }
    .backimg{
        border-radius: 10px 10px 0px 0px;  
      }
    .contacts{
        text-align: center;
        padding-left: 10%;
        padding-right: 0%;
    }
    .prodfont{
        height: 14vh;
        transform: translateY(0px);
    }
    .prodfont .button{
        opacity: 1;
    }
    .boxheight{
        width: 100%;
        height: 30%;
        box-shadow: 5px 0px 34px #bdbdbdc9;
        margin-bottom: 15px;
        border-radius: 10px;
        background-color: #ffffff;
    }
    .bluebox{
        width: 100%;
        margin-left: 10%;
        padding: 5%;
    }
    .whitebox{
        width: 100%;
        /* margin-left: 10%; */
        border-radius: 0px 0px 10px 10px;  
    }
    .about{
        height: 200px;
        margin-left: 15px;
    }
    .aboutpara{
        /* margin-left: 15px; */
        font-size: 25px;
        text-align: justify;
    }
    .aboutpara2{
        font-size: 15px;
    }
    /* .backimg{
        display: none;
    } */
    .weredo{
        /* width: 33.3333%; */
        font-size: 15px;
    }
    .whatwedo{
        height: 30vh;
    }    
    .gogreen{
        /* width: 33.3333%; */
        font-size: 10px;
    }
    .rrrimage{
        width: 80px;
        height: 60px;
    }
    .aboutpic{
        height: 45vh;
        left: 5vw;
        margin-bottom: 5vh;
    }
    .aboutwall{
     left: 0%;
     height: 100%;
    border-radius: 5px;
    width: 80%;   
    }
    .aboutwall2{
        right: 0;
        height: 55%;
        border-radius: 5px;
        width: 45%;
    }
    .style{
        height: 5vh;
    }
    .style label{
        font-size: 15px;
    }
    .style small{
        display: none;
    }
    /* .aboutpic{
        display: none;
    } */
    .content {
        position: relative;
        left: 5vw;
    }
    .abouthead{
        display: flex;
        justify-content: center;
        font-size: 35px;
        margin: 0;
    }
    .cntrbtn{
        display: flex;
        justify-content: center;
    }
}
@media only screen and (min-width:480px) and (max-width:539px) {
    .App{
        min-height: 50vh;
    }
    .boxheight{
        text-align: center;
        height: 35vh;
        /* box-shadow: 5px 0px 50px #acacac; */
    }
    .contactfont{
        font-size: 15px;
    }
    .bluebox{
        padding-top: 11%;
    }
    .style{
        height: 4vh;
        transition: none;
        -webkit-transition: none;
    }
    .style label{
        font-size: 25px;
    }
    .contacts{
        margin: 0%;
        height: 32vh;
    }
    .ttlbox{
        margin: 0%;
    }
    .App1{
        min-height: 0%;
    }
    .backimg{
        height: 30vh;
    }
    .whitebox{
        height: 30vh;
    }
    
    .aboutpic{
        height: 40vh;
        left: 60px;
    }
    .aboutwall{
        width: 75%;
    }
    .aboutwall2{
        right: 15%;
    }
    .abouthead{
        font-size: 30px;
        margin: 0%;
    }
    .aboutpara{
        font-size: 20px;
    }
    .aboutpara2{
        margin: 3% 0;
    }
    .borders{
        font-size: 20px;
    }
    .prodfont{
        height: 13vh;
    }
    .prodfont .button{
        opacity: 1;
    }
    .para{
        font-size: 15px;
        margin-bottom: 3%;
    }
    textarea.form-control{
        height: 5vh !important
     }
     .whatwedo{
         padding: 3%;
     }
     .weredo{
         font-size: 25px;
     }
     .gogreen{
         font-size: 15px;
     }
}

@media only screen and (min-width:990px) and (max-width:1080px) {
.aboutpic{
    height: 40vh;
}
.App{
    height: 50vh !important;
}
.box{
    height: 40vh;
}
.proimg{
    height: 25vh;
}
.prodfont{
    height: 35%;
}
.prodfont .button{
    opacity: 1;
}
}
@media only screen and (min-width:540px) and (max-width:720px){
    .App{
        min-height: 50vh;
    }
    .boxheight{
        text-align: center;
        height: 25vh;
        box-shadow: 5px 0px 50px #acacac;
        background-color: white;
        border-radius: 10px;
    }
    .contactfont{
        font-size: 15px;
    }
    .bluebox{
        padding-top: 11%;
    }
    .style{
        height: 4vh;
        transition: none;
        -webkit-transition: none;
    }
    .style label{
        font-size: 25px;
    }
    .contacts{
        margin: 0%;
        /* height: 32vh; */
    }
    .ttlbox{
        margin: 0%;
    }
    .App1{
        min-height: 0%;
    }
    .backimg{
        height: 35vh;
    }
    .whitebox{
        height: 35vh;
        margin-top: 2%;
    }
    .imgbox{
        width: 50%;
        margin-top: 2%;
    }
    .aboutpic{
        height: 40vh;
        left: 10vw;
    }
    .aboutwall{
        width: 75%;
    }
    .aboutwall2{
        right: 15%;
    }
    .abouthead{
        font-size: 25px;
        line-height: 15px;
        margin: 0%;
        text-align: center;
    }
    .aboutpara{
        font-size: 15px;
        text-align: center;
        line-height: 18px;
    }
    .aboutpara2{
        margin: 0;
        font-size: 15px;
        line-height: 20px;
    }
    .cntrbtn{
        display: flex;
        justify-content: center;
    }
    .borders{
        font-size: 20px;
    }
    .prodfont{
        height: 15vh;
        font-size: 15px;
        padding: 0%;
    }
    .prodfont .button{
        opacity: 1;
    }
    .para{
        font-size: 15px;
        margin-bottom: 3%;
    }
    textarea.form-control{
        height: 5vh !important
     }
     .whatwedo{
         padding:5% 3%;
     }
     .weredo{
         font-size: 25px;
     }
     .gogreen{
         font-size: 15px;
     }
     .content{
         position: normal !important;
     }
  }
  @media only screen and (min-width:720px) and (max-width:990px){
    .App{
        min-height: 50vh;
    }
    .boxheight{
        text-align: center;
        height: 35vh;
        /* box-shadow: 5px 0px 50px #acacac; */
    }
    .contactfont{
        font-size: 15px;
    }
    .bluebox{
        padding-top: 11%;
    }
    .style{
        height: 4vh;
        transition: none;
        -webkit-transition: none;
    }
    .style label{
        font-size: 25px;
    }
    .contacts{
        margin: 0%;
        height: 40vh;
    }
    .ttlbox{
        margin: 0%;
    }
    .App1{
        min-height: 0%;
    }
    .backimg{
        height: 40vh;
    }
    .whitebox{
        height: 40vh;
    }
    
    .aboutpic{
        height: 40vh;
        left: 60px;
    }
    .aboutwall{
        width: 75%;
    }
    .aboutwall2{
        right: 15%;
    }
    .abouthead{
        font-size: 30px;
        margin: 0%;
    }
    .aboutpara{
        font-size: 20px;
    }
    .aboutpara2{
        margin: 3% 0;
    }
    .borders{
        font-size: 20px;
    }
    .prodfont{
        height: 13vh;
    }
    .prodfont .button{
        opacity: 1;
    }
    .para{
        font-size: 15px;
        margin-bottom: 3%;
    }
    textarea.form-control{
        height: 5vh !important
     }
     .whatwedo{
         padding: 3%;
     }
     .weredo{
         font-size: 25px;
     }
     .gogreen{
         font-size: 15px;
     }
  }
/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */