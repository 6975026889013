.App{
  /* background-color: #ffffff; */
  /* min-height: 100vh; */
}
.App1{
  /* background-color: #ffffff; */
  /* min-height: 100vh; */
}
.background{
background-image: linear-gradient(279deg, #3b3380 , transparent);
background-size: 500%;
}
