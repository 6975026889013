.contact{
    font-family: 'Roboto', sans-serif;
    min-height: 70vh;
}
.contact_para{
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
    text-align: justify;
}
.contactbox{
    height: 25vh;
    margin: 5%;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgb(177, 176, 176);
}
/* .contacttext{
    font-size: 14px;
} */

@media only screen and (min-width: 768px) and (max-width:991px){
    .contactbox{
        height: 20vh;
        font-size: 10px;
    }
}