.popup{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
}
.popupbox{
    position: relative;
    width: 60%;
    margin: 0 auto;
    /* max-height: 70vh; */
    /* max-width: calc(100vh - 85vh - 20px); */
    background: white;
    border-radius: 10px;
    /* padding: 5%; */
    /* border: 1px solid black; */
    overflow: auto;
    top: 10vh;
    height: 80vh;
}
.closeicon{
    /* content: 'X'; */
    cursor: pointer;
    position: fixed;
    right: 22%;
    top: 12%;
    background: #3b3380;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 26px;
    text-align: center;
    font-size: 15px;
    color: white;
}
