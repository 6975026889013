.footer{
    /* height: 30vh; */
    bottom: 0px;
    /* background-color: #ffffff;
    margin: 0 !important;
    padding: 0 !important;
    bottom: 0px; */
}
.logo{
    width : 40%;
    /* height : 40%; */
    padding: 3%;
    /* border:1px solid gray; */
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 5px #e1e1e1;
}
.size{
    position:absolute;
    bottom: 5vh;
}
.section{
    /* background-color: white; */
    /* height: 26vh; */
    /* height: 9.5rem; */
    /* border-style: solid; */
    /* border-width: 5px 0 0; */
    /* background-color: rgb(245, 243, 243); */
    /* padding-bottom: 2% !important; */
}
.section2{
    border-style: solid;
    border-color: #3b3380;
    /* height: 6vh; */
    /* border-width: 0 0 5px; */
    background-color: #3b3380;
    color: white;
    /* text-align: center; */
    display: grid;
    align-items: center;
    /* border: ; */
}
.icons{
    padding-top:3px;
    /* width: 30px;
    height: 30px;
    margin: .5rem; */
    /* padding: 4% 0% 0% 20%; */
}
.copyright{
    font-size: 15px;
    /* color: rgb(0, 0, 0); */
    margin: 2px !important;
}

.footermenu{
    /* display: grid; */
/* justify-content: center; */
/* align-items: center; */
font-size: 15px;
text-align: justify;
}
.footermenu label{
    border: .5px solid rgb(110, 110, 110);
    padding: 5px;
    /* background-color:rgb(87, 87, 87) */
}
/* .footermenu label:hover{
    border: .5px solid tomato;
} */
.flabel{
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    /* font-size: 15px; */
   /* background-image: linear-gradient(279deg, #d5d5d5, white); */
   background-color: white;
}
.fc{
    margin: 2% 0;
}
.flabel i{
    /* padding-top: 8%; */
    color: #3b3380;
/* justify-content: center; */
}
.flabel h4{
    /* padding: 1%; */
    margin-top: 5%;
    text-align: center;
    font-size: 25px;
}
.flabel p{
    line-height: 0px;
    text-align: center;
    list-style-type: none;
    font-size: 13px;
}
/* .para{
    color: rgb(0, 0, 0);
    font-family: 'Titillium Web', sans-serif;
} */
@media only screen and (min-width:320px) and (max-width:480px) {
    .footer{
        /* height: 25vh; */
        /* margin: 0 !important; */
        /* padding: 0 !important */
    }
    .footermenu{
        font-size: 8px;
    }
    .logo{
        width: 50%;
        padding-top: 5%;

    }.icons{
        font-size: 6px;
        padding-top: 2px;
        /* width: 15px; */
        /* height: 15px; */
        /* margin: .5rem; */
    }
    .copyright{
        font-size: 9px;
        margin-bottom: 0% !important;
    }
    .flabel .fc{
        width: 50% !important;
    }
    /* .flabel center{
        font-size: 1.5rem !important;
    } */
    .flabel h4{
        font-size: 15px;
    }
    .flabel p{
        font-size: 10px;
    }
    .flabel .fa-3x{
        font-size: 1.5rem;
    }
}
@media only screen and (min-width:480px) and (max-width:990px) {
    .footer{
        height: 25vh;
    }
    .logo{
        width: 35%;
        padding-top: 5%;
    }
}
@media only screen and (min-width:480px) and (max-width:539px) {

  }
@media only screen and (min-width:540px) and (max-width:720px){
.flabel .fc{
    width: 50%;
}
    }
@media only screen and (min-width:720px) and (max-width:990px){

    }