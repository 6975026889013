.header{
    width: 100%;
    background-color: #3b3380;
    height: 11.5rem;
}
.ncp{
    height: 6.5rem;
    /* border-style: solid; */
    /* border-width: 5px 0 0; */
    background-color: rgb(255, 255, 255);
}
.head{
    font-family: 'Roboto', sans-serif;
    background-color: rgb(240, 240, 240);
    font-size: 40px;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    display: flex;
    height: 20vh;
}
.form{
    padding: 0% 10% 0 10%;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.ncp h3{
    font-family: 'Roboto', sans-serif;
    color: #2e75af;
    font-size: 16px;
    margin: 0% !important;
    float: right;
}
.ncp h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #424242;
    font-size: 22px;
    margin: 0% !important;
    float: right;
}
.d-flex{
    display: flex;
}
.hicons{
    padding: 1%;
}
.image{
    /* float:left ; */
    width: 50%;
    /* height: 75%  ; */
    margin-top: 1rem;
}
.menu{
    color: rgb(0, 0, 0) !important;
    justify-content: space-around;
    float: left;
    display: flex;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    padding-top: 2rem;
}
.menu label{
    /* display: block; */
    /* opacity: 1; */
    /* width: 75px; */
    /* z-index: 3; */
    /* color: black !important; */
}

a{
color: black !important;
text-decoration: none !important;
}

.menulogo{
  display: flex;
  justify-content: center;
}


  .dropdown-content {
    opacity: 0;
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px 5px 5px 5px ;

  }
  
  .dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: flex;
  }
  
  .dropdown-content a:hover {
    background-color: #3b3380;
    color: white !important;
    border-radius: 5px 5px 5px 5px;
 }
  
  .dropdown:hover .dropdown-content {
        display: block;
        opacity: 1;
        transition: all .6s ease-in;      
        -moz-transition: all .6s ease-in-out;
        -webkit-transition: all .6s ease-in-out;
    }
  























/* .navbtn{
    display: flex;
    justify-content: space-evenly;
} */

.navbtn a{
    transition: 0.3s ease;
  color: #000000;
  font-size: 15px;
  text-decoration: none;
  padding: 18px 0;
  margin: 0 20px;
  font-weight: 600;
  display: inline-block;
}

.navbtn a:hover{
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    padding: 3px 0; 
}

nav{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    }
/* nav a{
    position:relative; 
    display:inline-block; 
    padding:0 30px; 
    color:#fff; 
    font-size:14px; 
    letter-spacing:-.5px; 
    text-decoration:none;
} */

textarea.form-control{
   height: 15vh !important
}

/* reboot.scss a.hover{
text-decoration: none !important;
}  */
.mobile-menu{
    display: none;
  }
  
   /*================ Dropdown ================*/
   .dropdownfunc {
    /* display: block; */
    /* padding: 0; */
    position: fixed;
    top: 50px;
    left: 0;
    background-color: #ffffff;
    /* width: 100%; */
    z-index: 9999;
    border: 1px solid rgba(0, 0, 0, 0.04);
    /* box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
    /* color: black; */
  }
  .dropdownfunc ul {
    list-style: none;
    padding: 0;
    color: black;
    margin: 0;
  }
  .dropdownfunc li {
    padding: 8px 12px;      
    padding: 1em 5%;
    font-size: 12px;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;  
    font-weight: bold;
    transition: all 1s ease-out;
    border-bottom: 1px solid #bbbbbb;
  }
  .dropdownfunc li:active{
    color: #57b14b;
  }
  .dropdownfunc li a{
    color: black;
    font-weight: 500;
  }




/*=================================================== Responsive =============================================*/


@media only screen and (min-width:320px) and (max-width:480px) {
  .mobileview{
    display: none;
  }
  .menu{
    display: none;
  }
    .header{
      width: 100vw;
      height: 10vh;
    }
    .ncp {
        height: 10vh;
        position: fixed;
        background-color: white;
        z-index: 10000;
        border-bottom: 5px solid #3b3380;
        width: 100vw;
    }
    .image{
        width: 65%;
        height: 90%;
        margin-top: 2%;
    }
    .getquery{
      display: none;
    }

    .d-grid{
        display: none;
    }
    .mob{
        display: none;
    }
    .mobile-menu{
        display: block;
        color: #000000;
        margin-top: 5%; 
        float: right
      }
}
@media only screen and (min-width:480px) and (max-width:539px) {
  .mobileview {
    display: none;
}
    .header{
        height: 7.5vh;
     }
    
    .image{
        width: 75%;
        /* height: 60%; */
        /* margin-top: 1.5rem; */
    }
    .menulogo{
      padding-left: 10%;
      justify-content: normal;
      display: normal;
    }
    .d-grid{
        display: none;
    }
    .menu{
      /* font-size: 14px; */
      display: none;
    }
    .mob{
        display: none;
    }
    .ncp{
        height: 7vh;
        position: absolute;
        background-color: white;
        z-index: 10000;
        width: 100vw;
    }
    .getquery{
      display: none;
    }
    .mobile-menu{
        display: block;
        color: #000000;
        padding-top: 3%;
      }
      .dropdownfunc{
        height: 10vh;
      }
}
@media only screen and (min-width: 200px) and (max-width: 320px){
   .mobile-menu i{
     font-size: 15px !important;
     margin-top: 3%;
   }
  }
  /* @media screen and (min-width:540) {
    .image{
      width:40% !important
    }
  } */
  @media only screen and (min-width:540px) and (max-width:576px){
    .image{
      width: 40%;
      margin-top: 5px;
  }
  .mobileview {
    display: none;
}
.mobile-menu{
  display: block;
  color: #000000;
  padding-top: 3%;
}
    .header{
        height: 7.5vh;
     }
    .menulogo{
      padding-left: 10%;
      justify-content: normal;
      display: normal;
    }
    .d-grid{
        display: none;
    }
    .menu{
      /* font-size: 14px; */
      display: none;
    }
    .mob{
        display: none;
    }
    .ncp{
        height: 7vh;
        position: absolute;
        background-color: white;
        z-index: 10000;
        width: 100vw;
    }
    .getquery{
      display: none;
    }
  
      .dropdownfunc{
        height: 10vh;
      }
  }
  @media only screen and (min-width:576px) and (max-width:720px){
    .image{
      width: 40%;
      /* height: 60%; */
      margin-top: 5px;
  }
  .mobileview {
    display: none;
}
.mobile-menu{
  display: block;
  color: #000000;
  padding-top: 6%;
}
    .header{
        height: 7.5vh;
     }
    .menulogo{
      padding-left: 10%;
      justify-content: normal;
      display: normal;
    }
    .d-grid{
        display: none;
    }
    .menu{
      /* font-size: 14px; */
      display: none;
    }
    .mob{
        display: none;
    }
    .ncp{
        height: 7vh;
        position: absolute;
        background-color: white;
        z-index: 10000;
        width: 100vw;
    }
    .getquery{
      display: none;
    }
        .dropdownfunc{
        height: 10vh;
      }
  }
  @media only screen and (min-width:720px) and (max-width:990px){
    .image{
      width: 75%;
      /* height: 60%; */
      /* margin-top: 1.5rem; */
  }
  .mobileview {
    display: none;
}
    .header{
        height: 8.5vh;
     }
      
    .menulogo{
      padding-left: 10%;
      justify-content: normal;
      display: normal;
    }
    .d-grid{
        display: none;
    }
    .menu{
      /* font-size: 14px; */
      display: none;
    }
    .mob{
        display: none;
    }
    .ncp{
        height: 8vh;
        position: absolute;
        background-color: white;
        z-index: 10000;
        width: 100vw;
    }
    .getquery{
      display: none;
    }
    .mobile-menu{
        display: block;
        color: #000000;
        padding-top: 12px;
      }
      .dropdownfunc{
        height: 10vh;
      }
  }